export const skills_options = [
  { label: "---", value: "" },
  {
    label: "Paddle",
    value: "Paddle",
  },
  {
    label: "Freestyle",
    value: "Freestyle",
  },
  {
    label: "Backstroke",
    value: "Backstroke",
  },
  {
    label: "Breakstroke",
    value: "Breakstroke",
  },
  {
    label: "Butterfly",
    value: "Butterfly",
  },
];

export const healthinfo_options = [
  { label: "---", value: "" },
  {
    label: "Asthma",
    value: "Asthma",
  },
  {
    label: "Bronchitis",
    value: "Bronchitis",
  },
  {
    label: "Sergeon",
    value: "Sergeon",
  },
  {
    label: "Depression",
    value: "Depression",
  },
  {
    label: "Autism",
    value: "Autism",
  },
  {
    label: "Pregnancy",
    value: "Pregnancy",
  },
  {
    label: "Knees/Ankle pain",
    value: "Knees/Ankle pain",
  },
  {
    label: "Lower back pain",
    value: "Lower back pain",
  },
  {
    label: "Fear of deep water",
    value: "Fear of deep water",
  },
  {
    label: "Anxiety disorder",
    value: "Anxiety disorder",
  },
  {
    label: "Traumatic event",
    value: "Traumatic event",
  },
  {
    label: "Disability (Body part, arm, leg other)",
    value: "Disability (Body part, arm, leg other)",
  },
];

export const have_drown_options = [
  { label: "---", value: "" },
  {
    label: "Never",
    value: "Never",
  },
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "Yes, It was serious",
    value: "Yes, It was serious",
  },
];

export const genders = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
];

export const communicateLang = [
  {
    label: "Khmer",
    value: "Khmer",
  },
  {
    label: "English",
    value: "English",
  },
];

export const status_options = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Deactive",
    value: "Deactive",
  },
];

export const prefixes = [
  {
    label: "Mr",
    value: "Mr",
  },
  {
    label: "Ms",
    value: "Ms",
  },
  {
    label: "Mrs",
    value: "Mrs",
  },
  {
    label: "Mast",
    value: "Mast",
  },
];
export const class_types = [
  {
    label: "Private (Indoor)",
    value: "Private (Indoor)",
  },
  {
    label: "Private (Outdoor)",
    value: "Private (Outdoor)",
  },
  {
    label: "Join (2-3pax)",
    value: "Join (2-3pax)",
  },
  {
    label: "Join (4-5pax)",
    value: "Join (4-5pax)",
  },
];
export const parents = [
  {
    label: "Dad",
    value: "Dad",
  },
  {
    label: "Mum",
    value: "Mum",
  },
];
export const have_train = [
  {
    label: "Never",
    value: "Never",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];
export const photo_permission = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];
export const findus_options = [
  { label: "---", value: "" },
  {
    label: "Banner",
    value: "Banner",
  },
  {
    label: "Flyer",
    value: "Flyer",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
  {
    label: "Tiktok",
    value: "Tiktok",
  },
  {
    label: "Friend",
    value: "Friend",
  },
  {
    label: "Google/Other search engine",
    value: "Google/Other search engine",
  },
];
// 0-4 (0-4.99)
// 5-18 (5-18.99)
// 19 - 25 (19-25.99)
// 26-39 (26-39.99)
// 40-100+
export const age_options = [
  {
    label: "0-4y",
    value: "0-4y",
  },
  // {
  //   label: "5y-up",
  //   value: "5y-up",
  // },
  {
    label: "5-18y",
    value: "5-18y",
  },
  {
    label: "19-25y",
    value: "19-25y",
  },
  {
    label: "26-39y",
    value: "26-39y",
  },
  {
    label: "40-100+",
    value: "40-100+",
  },
];

export const complete_lesson_options = [
  {
    label: "Swim with Confidence",
    value: "Swim with Confidence",
  },
  {
    label: "Freestyle",
    value: "Freestyle",
  },
  {
    label: "Backstroke",
    value: "Backstroke",
  },
  {
    label: "Breaststroke",
    value: "Breaststroke",
  },
  {
    label: "Butterfly",
    value: "Butterfly",
  },
  {
    label: "Survival Stroke",
    value: "Survival Stroke",
  },
  {
    label: "Water safety and CPR",
    value: "Water safety and CPR",
  },
];

export const status_enabled = [
  {
    label: "Enabled",
    value: "Enabled",
  },
  {
    label: "Disabled",
    value: "Disabled",
  },
];
